@import-normalize;

:root {
    --color1: #da783c;
    --color2: #dfd7cd;
    --color3: #666699;
    --color4: #84CE55;
    --color5: #434357;
}

@font-face {
    font-family: 'Bristol';
    src: url('Bristol.otf');
    src:
      local('Bristol'),
      url('Bristol.otf')
      format('opentype');
}

@font-face {
    font-family: 'Gothic';
    src: url('DidactGothic-Regular.otf');
    src:
      local('Gothic'),
      url('DidactGothic-Regular.otf')
      format('opentype');
}

html, body, div, h1 {
    margin: 0;
    padding: 0;
}

html {
    font-size: 12pt;
    font-family: Gothic;
    color: var(--color3);
    line-height: 1.5em;
}

body {
    background: var(--color3);

}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    width: 90%;
    max-width: 1400px;
    min-width: 320px;
    margin: 0 auto;
    background: var(--color2);

    & > main {
        flex: 1 1 auto;
    }

    @media (max-width: 1560px){
        width: 100%;
        max-width: auto;
    }
}

h1 {

}

h2 {
    color: var(--color1);
    font-size: 20pt;
    font-weight: normal;
    text-align: center;
}

p {

}

.button-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: .5em auto;

    .button {
        margin: .3em 0;
    }
}

button {
    font-family: 'Gothic';
}

.hide-in-portrait {
    @media(orientation: portrait) {
        display: none !important;
    }
}

.hide-in-landscape {
    @media(orientation: landscape) {
        display: none !important;
    }
}
